// @ts-check

import { Component } from "react";
import SignatureCanvas from 'react-signature-canvas';

export default class SignatureField extends Component {
    sigPad = {};

    constructor(props) {
        super(props);

        this.state = {
            signature: props.signature,
            sigSaved: props.signature !== undefined
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.signature !== prevProps.signature && !this.props.is_submission) {
            this.setState({signature: this.props.signature});
        }
    }

    componentDidMount() {
        if (!this.state.signature && !this.props.is_submission) {
            this.sigPad?.off();
        }
    }

    clearSignature = () => {
        if (this.state.signature) {
            this.setState({signature: undefined, sigSaved: false});
        } else {
            this.sigPad?.clear();
        }

        this.props.saveSignature(this.props.field_id, "")

        this.setState({
            sigSaved: false
        }, () => {
            this.sigPad?.on();
        });
    }

    saveSignature = () => {
        if (this.state.signature) {
            return;
        }
        this.setState({sigSaved: true});
        const canvas = this.sigPad?.getTrimmedCanvas();
        const scaledCanvas = scaleCanvas(canvas, 500);
        const data = scaledCanvas.toDataURL("image/png");
        this.props.saveSignature(this.props.field_id, data);
    }

    render() {
        return (
            <div className="">
                <div className="print-avoid-breaks" style={{ position: "relative" }}>
                    {this.state.signature ? (
                        <img className="signature" src={this.state.signature} />
                    ) : (
                        // @ts-ignore
                        <SignatureCanvas
                            penColor="black"
                            backgroundColor="#f6f6f6"
                            ref={(ref) => {
                                this.sigPad = ref;
                            }}
                            onEnd={() => {
                                this.saveSignature();
                            }}
                        />
                    )}
                </div>
                {this.props.is_submission && (
                    <div className="signature-pad--footer">
                        <div className="signature-pad--actions">
                            <button type="button" className="ButtonLink zero-light-blue" onClick={this.clearSignature}>
                                Clear
                            </button>
                            <div className="description">
                                {this.props.is_submission && !this.state.sigSaved && "Sign above"}
                                {this.props.is_submission && this.state.sigSaved && "Saved"}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

/**
 * Scales a canvas to a set width, maintaining aspect ratio.
 * @param {HTMLCanvasElement} canvas 
 * @param {number} newWidth 
 * @returns {HTMLCanvasElement} A scaled copy of the canvas
 */
function scaleCanvas(canvas, newWidth) {
    const originalWidth = canvas.width;
    const originalHeight = canvas.height;

    // Calculate the aspect ratio
    const aspectRatio = originalHeight / originalWidth;

    // Calculate the new height maintaining the aspect ratio
    const newHeight = Math.round(newWidth * aspectRatio);

    // Create a temporary canvas to store the original content
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = newWidth;
    tempCanvas.height = newHeight;
    const tempContext = tempCanvas.getContext('2d');
    tempContext.drawImage(canvas, 0, 0, newWidth, newHeight);
    return tempCanvas;
}
