import { useCallback, useEffect, useRef, useState } from 'react';
import { Upload } from 'antd';
import { fileHasZeroSize } from 'other/Helper';
import { GLOBAL_ATTACHMENT_LIMIT } from 'other/Constants';

const MB = 1048576;

/**
 * Returns a friendly string error message if file does not meet contraints, or null if it's within parameters.
 * @param {File} file 
 * @param {object} options
 * @param {number?} options.imageSizeLimitMb 
 * @param {number?} options.videoSizeLimitMb 
 * @param {number?} options.defaultSizeLimitMb 
 * @returns {string?}
 */
function getSizeError(file, options = {}) {
    const {imageSizeLimitMb, videoSizeLimitMb, defaultSizeLimitMb} = options;
    const isVideo = file.type.startsWith('video');
    const isImage = file.type.startsWith('image');

    if (isVideo && videoSizeLimitMb) {
        if (file.size > videoSizeLimitMb * MB) {
            return `Video must be less than ${videoSizeLimitMb}MB`;
        }
    } else if (isImage && imageSizeLimitMb) {
        if (file.size > imageSizeLimitMb * MB) {
            return `Image must be less than ${imageSizeLimitMb}MB`;
        }
    } else if (defaultSizeLimitMb) {
        if (file.size > defaultSizeLimitMb * MB) {
            let type = isVideo ? 'Video' : isImage ? 'Image' : 'File';
            return `${type} must be less than ${defaultSizeLimitMb}MB`;
        }
    }

    return null;
}

export default function useFileUploadManager(
    attachments = [], 
    {
        attachmentLimit = GLOBAL_ATTACHMENT_LIMIT,
        imageSizeLimitMb = 20,
        videoSizeLimitMb = 500,
        defaultSizeLimitMb = 20,
        dragEventTarget = document,
        onDraggerChange,
        onFileListChange,
    } = {}
) {
    const [fileList, setFileList] = useState([]);
    const [error, setError] = useState(null);
    const [showDragger, setShowDragger] = useState(false);
    const dragTimer = useRef(null);

    const dragOverHandler = useCallback((e) => {
        const dt = e.dataTransfer;
        if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') !== -1 : dt.types.contains('Files'))) {
            setShowDragger(true);
            clearTimeout(dragTimer.current);
        }
    }, [setShowDragger, dragTimer]);

    const dragLeaveHandler = useCallback((e) => {
        if (e.relatedTarget && e.relatedTarget.nodeName.toLowerCase() === 'html') {
            // File hovered over TinyMCE window, don't hide dragger.
            return;
        }

        clearTimeout(dragTimer.current);
        dragTimer.current = setTimeout(function() {
            setShowDragger(false);
        }, 25);
    }, [setShowDragger, dragTimer]);

    useEffect(() => {
        dragEventTarget.addEventListener('dragover', dragOverHandler);
        dragEventTarget.addEventListener('dragleave', dragLeaveHandler);

        return () => {
            dragEventTarget.removeEventListener('dragover', dragOverHandler);
            dragEventTarget.removeEventListener('dragleave', dragLeaveHandler);
        }
    }, [dragOverHandler, dragLeaveHandler, dragEventTarget]);

    useEffect(() => {
        onDraggerChange?.(showDragger);
    }, [showDragger, onDraggerChange]);

    useEffect(() => {
        onFileListChange?.(fileList);
    }, [fileList, onFileListChange]);

    const beforeUploadHandler = useCallback((file, newFileList) => {
        setError(null);

        if (fileHasZeroSize(file)) {
            return false;
        }

        if (attachments.length + newFileList.length > attachmentLimit) {
            setError(`Maximum of ${attachmentLimit} attachments allowed.`);
            setShowDragger(false);
            return Upload.LIST_IGNORE;
        }

        const sizeError = getSizeError(file, {imageSizeLimitMb, videoSizeLimitMb, defaultSizeLimitMb});

        if (sizeError) {
            setError(sizeError);
            return false;
        }

        return true;
    }, [attachments, attachmentLimit, getSizeError, setError]);

    const addUploadingFile = useCallback(uid => {
        const newFile = {
            key: uid,
            uid,
            status: "uploading",
            name: "Uploading...",
            percent: 0
        }

        setFileList(fileList => [...fileList, newFile]);
    }, [setFileList]);

    const updateUploadProgress = useCallback((uid, progress) => {
        setFileList(fileList => {
            const file = fileList.find(file => file.uid === uid);

            if (file) {
                file.percent = progress;
                return [...fileList];
            }

            return fileList;
        });
    }, [setFileList]);

    const removeUploadingFile = useCallback((uid) => {
        setFileList(fileList => {
            const index = fileList.findIndex(file => file.uid === uid);
    
            if (index !== -1) {
                const newFileList = [...fileList];
                newFileList.splice(index, 1);
                return newFileList;
            }

            return fileList;
        });
    }, [setFileList]);

    return {
        fileList,
        error,
        showDragger,
        attachmentLimit,
        beforeUploadHandler,
        addUploadingFile,
        updateUploadProgress,
        removeUploadingFile,
        setError,
        setShowDragger,
    }
}