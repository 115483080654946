import { useState } from 'react';

import { ZeroQuill } from 'components/Shared/ZeroQuill.js';
import Button from 'components/Shared/Button';

export default function FieldComment({
    initialComment,
    onCancel,
    onSave,
}) {
    const [comment, setComment] = useState(initialComment ?? "");

    return (
        <div className="comments">
            <ZeroQuill
                value={comment}
                onChange={(value) => setComment(value)}
                placeholder='Add comment...'
            />
            <div className="flex" style={{gap: "0.5rem", marginTop: "5px"}}>
                <Button type="primary" onClick={() => onSave(comment)}>Save</Button>
                <Button type="discard" onClick={onCancel}>Cancel</Button>
            </div>
        </div>
    );
}
