import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux'

import RoutesList from './RoutesList'

import Header from '../components/Navigation/Header.js';
import SettingsPanel from '../components/Navigation/SettingsPanel.js';
import Footer from '../components/Navigation/Footer.js';
import SideBar from '../components/Navigation/SideBar.js';
import NotificationAside from '../components/NotificationAside/NotificationAside.js'
import LoadingIndicator from '../components/Shared/LoadingIndicator.js';
import TeamModal from '../components/Teams/TeamModal.js';
import BrowserNotifications from "../components/BrowserNotifications";
import AppLoader from 'components/AppLoader';

import {Result} from 'antd'
import '../assets/css/antd-custom.css'

import $ from 'jquery'
import moment from 'moment-timezone';

import safe_get from '../other/SafeGet';
import {
    isAdmin,
    isTeamLead,
    isMobileApp,
    isAdminPage,
    defaultMyTeams,
    formatNotifications,
    minPageHeight,
    mainContentContainerClass
} from '../other/Helper.js'
import {WebSocketHandler} from '../other/WebSockets';

import {
    update_organization_uuid, update_team_cookie, update_kiosk_mode_header,
    get_initial_data, update_session_uuid, get_user_analytics, update_user,
    getDontSetTimezoneCookie, isAuthTokenForKiosk
} from '../api/zero-api.js';

import en from 'world_countries_lists/data/countries/en/world.json';
import {ConfigProvider} from 'antd-country-phone-input';
import CountryCodeSorter from '../other/CountryCodeSorter';


// import whale from '../assets/css/img/whale-img.png'

import * as organizationActions from '../store/actions/OrganizationActions'
import * as userActions from '../store/actions/UserActions'
import * as teamsActions from '../store/actions/TeamsActions'
import * as notificationsActions from '../store/actions/NotificationsActions'
import * as feedHelperActions from '../store/actions/FeedHelperActions';
import * as formActions from '../store/actions/FormsActions';
import * as appActions from '../store/actions/AppActions';

import {
    update_current_location,
    updateIncidentsPagination,
    updateIncidentsFilterQuery,
    updateIncidentsPeriodQuery
} from '../store/actions/IncidentsActions';
import {updateAssignmentsCount} from '../store/actions/DashboardActions'
import SyncManager from 'components/Offline/SyncManager';
import OfflineDataCaches from 'components/Offline/OfflineDataCaches';
import {isOfflineRoute} from 'offline/utils';
import SideBarWrapper from 'components/Navigation/SideBarWrapper';
import ZeroContextManager from 'components/ZeroContext';
import InitialDataCache from 'offline/InitialDataCache';
import { INITIAL_FORMS_PERIOD_QUERY } from 'store/helpers/defaults';

class Main extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        var debounceDate = new Date();

        this.state = {
            user: {},
            team: {},
            loading: true,
            sideMenuOpen: false,
            asideOpen: false,
            asideDebounce: debounceDate.getTime(),
            sideMenuDebounce: debounceDate.getTime(),
            error_occurred: false,
            error_message: "Something unusual happened. Click below to reload.",
            error_button: "Reload",
            error_code: "unknown",
            error_code_message: "unknown"
        };

        this.openAside = this.openAside.bind(this);
        this.closeAside = this.closeAside.bind(this);

        this.openSettings = this.openSettings.bind(this);
        this.closeSettings = this.closeSettings.bind(this);

        this.getInitialData = this.getInitialData.bind(this);
        this.toggleSideMenu = this.toggleSideMenu.bind(this);
        this.closeSideMenu = this.closeSideMenu.bind(this);

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("resize", this.updateDimensions);
        window.removeEventListener("orientationchange", this.updateDimensions);
    }

    componentDidMount() {
        this._isMounted = true;
        if (navigator.onLine) {
            this.getInitialData();
        } else {
            this.setState({loading: false}, () => {
                if (!isOfflineRoute()) {
                    if (isAuthTokenForKiosk()) {
                        this.props.history.replace(`/kiosk_mode`);
                    } else {
                        this.props.history.replace(`/${this.props.organization.organization_uuid}/home/dashboard`)
                    }
                }
            });
        }

        window.addEventListener("resize", this.updateDimensions);
        window.addEventListener("orientationchange", this.updateDimensions);

        this.updateDimensions();
    }

    getInitialData() {
        var self = this;
        var newState = {}
        this.setState({loading: true});

        this.props.dispatch(appActions.update_main_loading(true));
        this.props.dispatch(feedHelperActions.update_feed_position(0));
        this.props.dispatch(formActions.updateFormsPagePosition(0));
        this.props.dispatch(formActions.updateFormsPagination(1));

        var path_org_uuid = this.props.location.pathname.split("/")[1];
        var path_team_uuid = this.props.location.pathname.split("/")[4];
        // var path_location_uuid = this.props.location.pathname.split("/")[5];

        update_organization_uuid(path_org_uuid);

        get_initial_data().then(function (success) {
            success.json().then(async success => {
                const orgId = safe_get(success, "organization.organization_uuid", null);
                const userId = safe_get(success, "user.uuid", null);
                if (orgId && userId) {
                    try {
                        const initialDataCache = new InitialDataCache(orgId, userId);
                        await initialDataCache.set(success);
                    } catch (err) {
                        console.error("Could not update initial data cache:", err);
                    }
                }
                if (success.kiosk_mode_on) {
                    var org_uuid = safe_get(success, "organization.organization_uuid", "undefined");
                    update_organization_uuid(org_uuid);
                    update_kiosk_mode_header(true);
                    const path = window.location.pathname;
                    if (isMobileApp()) {
                        window.location = "/kiosk_mode?redirect=" + path;
                    } else {
                        self.props.history.push("/kiosk_mode?redirect=" + path);
                    }
                } else {
                    var is_kiosk_mode = safe_get(success, "kiosk_mode_user", false)
                    self.props.dispatch(appActions.update_kiosk_mode(is_kiosk_mode));
                    update_kiosk_mode_header(is_kiosk_mode);

                    let organizations = safe_get(success, "organizations", []);
                    self.props.dispatch(organizationActions.update_organizations(organizations));

                    var organization = safe_get(success, "organization", {});
                    self.props.dispatch(organizationActions.update_current_org(organization));
                    update_organization_uuid(organization.organization_uuid);

                    var session_uuid = safe_get(success, "session.session_uuid", "");
                    update_session_uuid(session_uuid);

                    var user = safe_get(success, "user", {});
                    self.props.dispatch(userActions.reset_user());
                    self.props.dispatch(userActions.update_current_user(user));

                    self.getUserAnalytics(user);
                    self.updateUserTimezone(user);

                    let all_notifications = safe_get(success, "notifications_data.notifications", []);
                    formatNotifications(self, all_notifications, user);

                    let unread_notifications_counter = safe_get(success, "notifications_data.total_unread", 0);
                    self.props.dispatch(notificationsActions.update_unread_notifications_counter(unread_notifications_counter));

                    self.props.dispatch(updateAssignmentsCount(safe_get(success, "assignment_count", 0)));

                    newState["user"] = user;

                    var teams = safe_get(success, "teams", []);

                    teams.sort(function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    });

                    self.props.dispatch(teamsActions.update_teams(teams));

                    if (organization.account_locked) {
                        window.location = "/account_locked";
                    }

                    if (teams.length === 0) {
                        self.props.history.push("/" + organization.organization_uuid + "/home/dashboard");
                        self.props.dispatch(teamsActions.update_current_team({}));
                    }
                        // else if (path_team_uuid) {
                        //   var found_team = teams.find(function(element) {
                        //     return element.uuid === path_team_uuid;
                        //   });
                        //   if (found_team) {
                        //     self.props.dispatch(currentTeamActions.update_current_team(found_team));
                        //     self.props.dispatch(feedHelperActions.update_feed_query("?team_uuid=" + path_team_uuid));
                        //     update_team_cookie(found_team.uuid);
                        //   }
                        //   else {
                        //     self.props.dispatch(currentTeamActions.update_current_team(defaultMyTeams));
                        //     self.props.dispatch(feedHelperActions.update_feed_query("?team_uuid=my_teams"));
                        //     update_team_cookie("my_teams");
                        //   }
                    // }
                    else {
                        self.props.dispatch(teamsActions.update_current_team(defaultMyTeams));
                        self.props.dispatch(feedHelperActions.update_feed_query("?team_uuid=my_teams"));
                        self.props.dispatch(feedHelperActions.update_date_query("&period=all_time"));

                        self.props.dispatch(formActions.updateFormsFilterQuery("?team_uuid=my_teams"));
                        self.props.dispatch(formActions.updateFormsPeriodQuery(INITIAL_FORMS_PERIOD_QUERY));

                        update_team_cookie("my_teams");
                    }

                    var location = {"location_uuid": "all_locations"};
                    self.props.dispatch(update_current_location(location));
                    self.props.dispatch(updateIncidentsFilterQuery(""));
                    self.props.dispatch(updateIncidentsPeriodQuery("&period=all_time"));
                    self.props.dispatch(updateIncidentsPagination(1));

                    newState["loading"] = false;
                    newState["error_occurred"] = false;
                    self.setState(newState);

                    self.props.dispatch(appActions.update_main_loading(false));
                }
            });
        }, function (error) {
            var newState = {};
            if (error.status === 403) {
                self.props.history.push("/403");
                // newState["error_message"] = "You don't have access to this page.";
                // newState["error_button"] = "Back to ZERO";
                // newState["error_code"] = "403";
                // newState["error_code_message"] = error.statusText
            } else {
                newState["loading"] = false;
                newState["error_occurred"] = true;
                newState["error_message"] = "Something unusual happened. Click below to reload.";
                newState["error_button"] = "Reload";
                newState["error_code"] = error.status || "Unknown error code";
                newState["error_code_message"] = error.statusText || "Unknown error message"
            }

            if (self._isMounted) {
                self.setState(newState);
                self.props.dispatch(appActions.update_main_loading(false));
            }

        });

    }

    updateUserTimezone = (user) => {
        var self = this;

        if (getDontSetTimezoneCookie() === '1') return;

        let {first_name, last_name, title, mobile_phone_number, phone_number} = user;
        var body = JSON.stringify({
            first_name: first_name,
            last_name: last_name,
            title: title,
            phone_number: phone_number,
            mobile_phone_number: mobile_phone_number,
            avatar: user.avatar_data.urls.original,
            timezone: moment.tz.guess()
        });

        update_user(user.uuid, body).then(success => {
            success.json().then(success => {
                var user = safe_get(success, "user", {});
                self.props.dispatch(userActions.update_current_user(user));
            });
        })
    }

    getUserAnalytics = (user) => {
        var self = this;
        get_user_analytics(user.uuid).then(function (success) {
            success.json().then(success => {
                let stats = safe_get(success, "user_stats", {});
                self.props.dispatch(userActions.update_user_analytics(stats));
            });
        }, function (error) {
            console.log(error);
        });
    }

    updateDimensions() {
        let width = window.innerWidth;
        if (width <= 1100 && this.props.is_desktop_size) {
            this.props.dispatch(appActions.is_desktop_size(false));
        } else if (width > 1100 && !this.props.is_desktop_size) {
            this.props.dispatch(appActions.is_desktop_size(true));
            this.setState({sideMenuOpen: false});
        }

    }

    openAside() {
        var debounceDate = new Date();
        if (this.state.asideOpen || (debounceDate.getTime() - this.state.asideDebounce < 175)) {
            return;
        }
        this.setState({asideDebounce: debounceDate.getTime(), asideOpen: true});
    }

    closeAside() {
        var debounceDate = new Date();
        if (!this.state.asideOpen || (debounceDate.getTime() - this.state.asideDebounce < 175)) {
            return;
        }
        this.setState({asideDebounce: debounceDate.getTime(), asideOpen: false});
    }

    openSettings() {
        var debounceDate = new Date();
        if (this.state.settingsOpen || (debounceDate.getTime() - this.state.settingsDebounce < 175)) {
            return;
        }
        this.setState({settingsDebounce: debounceDate.getTime(), settingsOpen: true});
    }

    closeSettings() {
        var debounceDate = new Date();
        if (!this.state.settingsOpen || (debounceDate.getTime() - this.state.settingsDebounce < 175)) {
            return;
        }
        this.setState({settingsDebounce: debounceDate.getTime(), settingsOpen: false});
    }

    toggleSideMenu() {
        this.setState({sideMenuOpen: !this.state.sideMenuOpen})
        // $('body').addClass('stop-scrolling');
        $('div#content-container').bind('touchstart', function (e) {
            e.preventDefault();
        });
    }

    closeSideMenu() {
        var debounceDate = new Date();
        if (!this.state.sideMenuOpen || (debounceDate.getTime() - this.state.sideMenuDebounce < 200)) {
            return;
        }
        this.setState({sideMenuDebounce: debounceDate.getTime(), sideMenuOpen: false})
        // $('body').removeClass('stop-scrolling');
        $('div#content-container').unbind('touchstart');
    }

    touchMove(e) {
        e.preventDefault();
    }

    render() {
        var asideClass = (this.state.asideOpen) ? "aside-in" : "";
        var containerClass = "effect mainnav-lg mainnav-fixed aside-bright aside-float " + asideClass //+ " container-custom";

        if (!this.props.is_desktop_size) {
            containerClass = (this.state.sideMenuOpen ? "effect aside-bright aside-float mainnav-in " : "effect aside-bright aside-float ") + asideClass
        }

        return (
            <AppLoader loading={this.state.loading}>
                <WebSocketHandler>
                    <ConfigProvider locale={en}>
                        <CountryCodeSorter>
                            <OfflineDataCaches>
                                <ZeroContextManager>
                                    <SyncManager/>
                                    <BrowserNotifications history={this.props.history}/>
                                    <div>
                                        <div id="container" className={containerClass}>
                                            <div className="boxed" style={{minHeight: minPageHeight()}}>
                                                <Header
                                                    toggleSideMenu={this.toggleSideMenu}
                                                    openAside={this.openAside}
                                                    openSettings={this.openSettings}
                                                    asideOpen={this.state.asideOpen}
                                                />
                                                {
                                                    this.state.settingsOpen &&
                                                    <SettingsPanel close={this.closeSettings}/>
                                                }
                                                {
                                                    this.props.main_loading &&
                                                    <div id="content-container" className={"container-with-header"}>
                                                        <LoadingIndicator/>
                                                    </div>
                                                }
                                                {
                                                    !this.props.main_loading && this.state.error_occurred &&
                                                    <div id="content-container" className="container-with-header">
                                                        <div className={mainContentContainerClass()}
                                                            style={{marginLeft: "0px"}}>
                                                            <div id="page-content" style={{paddingTop: "10px"}}>
                                                                <div className="panel bulletin-post-border">
                                                                    <Result
                                                                        status="404"
                                                                        title="Error"
                                                                        subTitle={this.state.error_message}
                                                                        extra={
                                                                            <button className="btn btn-primary" style={{
                                                                                marginTop: "8px",
                                                                                display: "block",
                                                                                margin: "auto",
                                                                                marginBottom: "10px"
                                                                            }} onClick={() => {
                                                                                update_organization_uuid("");
                                                                                window.location = "/";
                                                                            }}>{this.state.error_button}</button>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.showTeamModal &&
                                                    <TeamModal
                                                        show={this.state.showTeamModal}
                                                        cancel={() => {
                                                            this.setState({showTeamModal: false});
                                                        }}
                                                        type="new"
                                                    />
                                                }
                                                {
                                                    !this.props.main_loading && !this.state.error_occurred &&
                                                    <RoutesList
                                                        isTeamLead={isTeamLead(this.props.user)}
                                                        isAdmin={isAdmin(this.props.user)}
                                                    />
                                                }
                                                {
                                                    this.state.asideOpen &&
                                                    <NotificationAside ref="notificationsAside" closeAside={this.closeAside}
                                                                    asideOpen={this.state.asideOpen}/>
                                                }
                                                {
                                                    this.state.sideMenuOpen &&
                                                    <SideBarWrapper Child={SideBar} closeSideMenu={this.closeSideMenu}
                                                                    sideMenuOpen={this.state.sideMenuOpen}
                                                                    showTeamModal={() => {
                                                                        this.setState({showTeamModal: true})
                                                                    }}/>
                                                }
                                                {
                                                    isMobileApp() && !isAdminPage(this.props.location.pathname) && !this.props.location.pathname.includes("/submission/") &&
                                                    <Footer/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </ZeroContextManager>
                            </OfflineDataCaches>
                        </CountryCodeSorter>
                    </ConfigProvider>
                </WebSocketHandler>
            </AppLoader>
        );
    }
}

const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        current_team: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        unread_notifications: safe_get(store, "notifications.notifications", {}),
        main_loading: safe_get(store, "app.loading", false),
        is_desktop_size: safe_get(store, "app.desktopSize", true),
        kiosk_mode: safe_get(store, "app.kiosk_mode", false)
    }
}

export default withRouter(connect(mapStateToProps)(Main));