import { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router";
import { Switch } from "react-router-dom";

import safe_get from "../other/SafeGet";

const Dashboard = lazy(() => import("../components/Dashboard/Dashboard.js"));

const ArticleHistory = lazy(() => import("../components/Library/ArticleHistory.js"));
const Article = lazy(() => import("../components/Library/ArticleView.js"));
const EditPost = lazy(() => import("../components/Library/EditPost.js"));
const Library = lazy(() => import("../components/Library/Library.js"));
const NewPost = lazy(() => import("../components/Library/NewPost.js"));
const UploadAttachment = lazy(() => import("../components/Library/UploadAttachment.js"));

const ManageLocations = lazy(() => import("../components/AdminPages/ManageLocations/ManageLocations"));
const EditIncident = lazy(() => import("../components/Incidents/Incident/EditIncident.js"));
const Incidents = lazy(() => import("../components/Incidents/Incidents.js"));

const BulletinEditPost = lazy(() => import("../components/Bulletin/BulletinEditPost.js"));
const BulletinFeed = lazy(() => import("../components/Bulletin/BulletinFeed.js"));
const BulletinNewPost = lazy(() => import("../components/Bulletin/BulletinNewPost.js"));
const BulletinView = lazy(() => import("../components/Bulletin/BulletinView.js"));

const Forms = lazy(() => import("../components/Forms/Forms.js"));
const EditSubmission = lazy(() => import("../components/Forms/EditSubmission.js"));
const NewForm = lazy(() => import("../components/Forms/NewForm.js"));
const SubmissionView = lazy(() => import("../components/Forms/SubmissionView.js"));

const ManageFormAlerts = lazy(() => import("../components/AdminPages/ManageFormAlerts/ManageFormAlerts.js"));
const ManageForms = lazy(() => import("../components/AdminPages/ManageForms/ManageForms.js"));
const ManageCalendar = lazy(() => import("../components/AdminPages/ManageSchedules/ManageCalendar"));
const ManageSchedules = lazy(() => import("../components/AdminPages/ManageSchedules/ManageSchedules"));
const ScheduleAnalytics = lazy(() => import("../components/AdminPages/ManageSchedules/ScheduleAnalytics"));

const Account = lazy(() => import("../components/Account/Account.js"));
const NewOrganization = lazy(() => import("../components/AdminPages/AccountSettings/NewOrganization"));
const OrgSettings = lazy(() => import("../components/AdminPages/AccountSettings/Settings.js"));
const ManageAPIKeys = lazy(() => import("../components/AdminPages/ManageAPIKeys/ManageAPIKeys"));
const KioskModeSettings = lazy(() => import("../components/AdminPages/ManageKiosks/KioskModeSettings"));
const ManageMembers = lazy(() => import("../components/AdminPages/ManageMembers.js"));
const ManageTeams = lazy(() => import("../components/AdminPages/ManageTeams/ManageTeams.js"));

import InlineSideBar from "../components/Navigation/InlineSideBar";
const NoTeamPage = lazy(() => import("../components/NoTeamPage.js"));

import { canManageSpaces, containerClass, isAdminOrTeamLead } from "../other/Helper";

import * as membersFilterActions from "../store/actions/MembersFilterActions";

import {
    update_date_query,
    update_feed_position,
    update_feed_query,
    update_feed_tab,
} from "../store/actions/FeedHelperActions";
import {
    updateFormsFilterQuery,
    updateFormsPagePosition,
    updateFormsPagination,
    updateFormsPeriodQuery,
    updateFormsTab,
} from "../store/actions/FormsActions";

import {
    update_current_location,
    updateIncidentsFilterQuery,
    updateIncidentsPagePosition,
    updateIncidentsPagination,
    updateIncidentsPeriodQuery,
    updateIncidentsTab,
} from "../store/actions/IncidentsActions";

const ManageDesks = lazy(() => import("components/AdminPages/ManageDesks/ManageDesks"));
const ManageFormPage = lazy(() => import("components/AdminPages/ManageForms/ManageFormPage"));
const PostSubmissionConfirmationPage = lazy(() => import("components/Bulletin/PostSubmissionConfirmationPage"));
import SideBarWrapper from "components/Navigation/SideBarWrapper";
import OfflineDashboard from "components/Offline/OfflineDashboard";
import OfflineDebugPage from "components/Offline/OfflineDebugPage";
const CheckIn = lazy(() => import("components/Spaces/CheckIn"));
const DirectoryPage = lazy(() => import("components/Spaces/DirectoryPage"));
const MyCheckIns = lazy(() => import("components/Spaces/MyCheckIns"));
const ViewMapPage = lazy(() => import("components/Spaces/ViewMapPage"));
import $ from "jquery";
import { INITIAL_FORMS_PERIOD_QUERY } from "store/helpers/defaults";

const manageTemplatesMatch = `manage_forms_templates|manage_post_templates|manage_incident_templates|manage_courses`;

class RoutesList extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // var location = prevProps.location.pathname;
        // if (location.includes("dashboard") || location.includes("incident") || location.includes("edit") || location.includes("new") || location.includes("library") || (!location.includes("/home/team/") && !location.includes("/home/my_posts"))) {
        //   this.props.dispatch(update_feed_position(0));
        //   this.props.dispatch(update_feed_query("?team_uuid=" + this.props.currentTeam.uuid));
        //   this.props.dispatch(update_date_query("&period=all_time"));
        // }

        var location = safe_get(this.props, "location.pathname", "");

        if (
            (!location.includes("/feed") && !location.includes("/access_teams")) ||
            location.includes("edit") ||
            location.includes("new")
        ) {
            this.props.dispatch(update_feed_position(0));
            this.props.dispatch(update_feed_tab("posts"));
            this.props.dispatch(update_feed_query("?team_uuid=my_teams"));
            this.props.dispatch(update_date_query("&period=all_time"));
        }

        if (location.includes("/feed/tab/analytics") && window.location.search.includes("export=true")) {
            this.props.dispatch(update_feed_query(window.location.search));
        }

        if (!location.includes("/forms") && !location.includes("/courses")) {
            this.props.dispatch(updateFormsPagination(1));
            this.props.dispatch(updateFormsPagePosition(0));
            this.props.dispatch(updateFormsFilterQuery("?team_uuid=my_teams"));
            this.props.dispatch(updateFormsPeriodQuery(INITIAL_FORMS_PERIOD_QUERY));
            this.props.dispatch(updateFormsTab("forms"));
        }

        if (!location.includes("/incidents")) {
            this.props.dispatch(updateIncidentsPagination(1));
            this.props.dispatch(updateIncidentsPagePosition(0));

            let location = { location_uuid: "all_locations" };
            this.props.dispatch(update_current_location(location));
            this.props.dispatch(updateIncidentsFilterQuery(""));

            this.props.dispatch(updateIncidentsPeriodQuery("&period=all_time"));
            this.props.dispatch(updateIncidentsTab("incidents"));
        }

        if (!location.includes("directory") && !location.includes("/users/")) {
            this.props.dispatch(membersFilterActions.update_members_filter([]));
        }
    }

    render() {
        const AdminRoutes = [
            <Route path="/:org_uuid/home/settings/:tab?" component={OrgSettings} />,

            <Route path="/:org_uuid/home/create_organization" component={NewOrganization} />,

            <Route path="/:org_uuid/home/manage_kiosks" component={KioskModeSettings} />,

            <Route path="/:org_uuid/home/manage_api_keys" component={ManageAPIKeys} />,
        ];

        const TeamLeadRoutes = [
            <Route path="/:org_uuid/home/incidents/location/:location_uuid/:tab?" exact component={Incidents} />,

            <Route
                path="/:org_uuid/home/incidents/location/:location_uuid/new_incident/:tab"
                exact
                component={EditIncident}
            />,

            <Route
                path="/:org_uuid/home/incidents/location/:location_uuid/edit_incident/:incident_uuid/:tab"
                exact
                component={EditIncident}
            />,

            <Route path="/:org_uuid/home/manage_schedules" exact component={ManageSchedules} />,
            <Route path="/:org_uuid/home/manage_calendar/:pageType?" exact component={ManageCalendar} />,

            <Route path={`/:org_uuid/home/:manage_form_type(${manageTemplatesMatch})`} exact component={ManageForms} />,
            <Route
                path={`/:org_uuid/home/:manage_form_type(${manageTemplatesMatch})/new_form`}
                exact
                component={NewForm}
            />,
            <Redirect
                from={`/:org_uuid/home/:manage_form_type(${manageTemplatesMatch})/edit_form/:form_uuid`}
                to="/:org_uuid/home/:manage_form_type/:form_uuid/editor"
                exact
            />,
            <Redirect
                from={`/:org_uuid/home/:manage_form_type(${manageTemplatesMatch})/analytics/:form_uuid`}
                to="/:org_uuid/home/:manage_form_type/:form_uuid/analytics"
                exact
            />,
            <Route
                path={`/:org_uuid/home/:manage_form_type(${manageTemplatesMatch})/:form_uuid/:tabName?`}
                exact
                component={ManageFormPage}
            />,

            <Route path="/:org_uuid/home/manage_courses_schedules" exact component={ManageSchedules} />,

            <Route path="/:org_uuid/home/access_teams" component={ManageTeams} />,

            <Route path="/:org_uuid/home/manage_incidents_locations" component={ManageLocations} />,

            <Route path="/:org_uuid/home/directory" component={ManageMembers} />,

            <Route
                path="/:org_uuid/home/manage_schedules/analytics/:schedule_uuid"
                exact
                component={ScheduleAnalytics}
            />,

            <Route path="/:org_uuid/home/manage_form_alerts" exact component={ManageFormAlerts} />,

            <Route
                path="/:org_uuid/home/team/:team_uuid/private_library/new_attachment"
                component={UploadAttachment}
            />,
        ];

        var NonAdminRoutes = [
            <Redirect
                exact
                from="/"
                to={{
                    pathname: `/${this.props.organization.organization_uuid}/home/dashboard`,
                    search: this.props.location.search,
                }}
            />,

            // DASHBOARD
            <Route path="/:org_uuid/home/dashboard" exact component={Dashboard} />,

            // LIBRARY
            <Route path="/:org_uuid/home/team/:team_uuid/private_library" exact component={Library} />,

            <Route path="/:org_uuid/home/team/:team_uuid/private_library_tag/:tag" component={Library} />,

            <Route
                path="/:org_uuid/home/team/:team_uuid/private_library/article/:article_uuid"
                exact
                component={Article}
            />,
            <Route
                path="/:org_uuid/home/team/:team_uuid/shared_library/article/:article_uuid"
                exact
                component={Article}
            />,

            <Route path="/:org_uuid/home/team/:team_uuid/private_library/new_post" component={NewPost} />,
            <Route path="/:org_uuid/home/team/:team_uuid/private_library/edit/:article_uuid" component={EditPost} />,

            <Route
                path="/:org_uuid/home/team/:team_uuid/private_library/article/:article_uuid/timeline"
                exact
                component={ArticleHistory}
            />,
            <Route
                path="/:org_uuid/home/team/:team_uuid/shared_library/article/:article_uuid/timeline"
                exact
                component={ArticleHistory}
            />,

            // BULLETIN
            // <Route path='/:org_uuid/home/my_posts' exact component={BulletinFeed} />,

            <Route path="/:org_uuid/home/team/:team_uuid/feed" exact component={BulletinFeed} />,
            <Route path="/:org_uuid/home/team/:team_uuid/feed/tab/:tab" exact component={BulletinFeed} />,

            <Route path="/:org_uuid/home/my_posts/post/:post_uuid" exact component={BulletinView} />,
            // <Route path='/:org_uuid/home/my_posts/post/:post_uuid/print' exact component={BulletinView}/>,

            <Route path="/:org_uuid/home/my_posts/edit/:post_uuid" component={BulletinEditPost} />,

            <Route path="/:org_uuid/home/team/:team_uuid/feed/post/:post_uuid" exact component={BulletinView} />,
            // <Route path='/:org_uuid/home/team/:team_uuid/feed/post/:post_uuid/print' exact
            //        component={BulletinView}/>,

            <Route path="/:org_uuid/home/team/:team_uuid/feed/new_post" exact component={BulletinNewPost} />,
            <Route path="/:org_uuid/home/team/:team_uuid/feed/new_post/:post_uuid" exact component={BulletinNewPost} />,
            <Route
                path="/:org_uuid/home/team/:team_uuid/feed/post_submitted"
                exact
                component={PostSubmissionConfirmationPage}
            />,

            <Route path="/:org_uuid/home/team/:team_uuid/feed/edit/:post_uuid" component={BulletinEditPost} />,

            // FORMS
            <Route path="/:org_uuid/home/team/:team_uuid/forms/:tab?" exact component={Forms} />,

            <Route
                path="/:org_uuid/home/team/:team_uuid/forms/submission_view/:submission_uuid"
                exact
                component={SubmissionView}
            />,

            <Route path="/:org_uuid/home/team/:team_uuid/forms/:form_uuid/:tab" exact component={EditSubmission} />,

            <Route
                path="/:org_uuid/home/team/:team_uuid/forms/:form_uuid/assignment/:assignment_uuid/:tab"
                exact
                component={EditSubmission}
            />,

            <Route
                path="/:org_uuid/home/team/:team_uuid/forms/:form_uuid/submission/:submission_uuid"
                exact
                component={EditSubmission}
            />,

            // COURESES
            <Route path="/:org_uuid/home/team/:team_uuid/courses/:tab?" exact component={Forms} />,

            <Route
                path="/:org_uuid/home/team/:team_uuid/courses/submission_view/:submission_uuid"
                exact
                component={SubmissionView}
            />,

            <Route path="/:org_uuid/home/team/:team_uuid/courses/:form_uuid/:tab" exact component={EditSubmission} />,

            <Route
                path="/:org_uuid/home/team/:team_uuid/courses/:form_uuid/assignment/:assignment_uuid/:tab"
                exact
                component={EditSubmission}
            />,

            <Route
                path="/:org_uuid/home/team/:team_uuid/courses/:form_uuid/submission/:submission_uuid"
                exact
                component={EditSubmission}
            />,

            // USER ACCOUNT

            <Route path="/:org_uuid/home/myprofile/:user_uuid" component={Account} />,

            <Route path="/:org_uuid/users/:user_uuid" exact component={Account} />,

            <Route path="/:org_uuid/feed/users/:user_uuid/" exact component={Account} />,

            <Route path="/:org_uuid/home/no_team" exact component={NoTeamPage} />,

            // SPACES
            <Route path="/:org_uuid/home/user_directory" exact component={DirectoryPage} />,
            <Route path="/:org_uuid/home/view_maps" exact component={ViewMapPage} />,
            <Route path="/:org_uuid/checkin/:checkInId?" exact component={CheckIn} />,
            <Route path="/:org_uuid/home/my_check_ins" exact component={MyCheckIns} />,

            // OFFLINE
            <Route path="/:org_uuid/offline/dashboard" exact component={OfflineDashboard} />,
            <Route path="/:org_uuid/offline/debug" exact component={OfflineDebugPage} />,
        ];

        const Special_routes = [
            <Route path="/:org_uuid/home/incidents/location/:location_uuid/:tab?" exact component={Incidents} />,
            <Route
                path="/:org_uuid/home/incidents/location/:location_uuid/new_incident/:tab"
                exact
                component={EditIncident}
            />,
            <Route
                path="/:org_uuid/home/incidents/location/:location_uuid/edit_incident/:incident_uuid/:tab"
                exact
                component={EditIncident}
            />,
        ];

        if (!isAdminOrTeamLead(this.props.user) && this.props.organization.incidents_enabled) {
            NonAdminRoutes = NonAdminRoutes.concat(Special_routes);
        }

        if (canManageSpaces(this.props.user)) {
            NonAdminRoutes.push(<Route path="/:org_uuid/home/manage_desks/:tabName?" exact component={ManageDesks} />);
        }

        const TeamLeads_Routes = NonAdminRoutes.concat(TeamLeadRoutes);
        const Admin_Routes = TeamLeads_Routes.concat(AdminRoutes);

        return (
            <div
                id="content-container"
                className={containerClass()}
                style={{ maxWidth: "1100px", top: this.props.teams.length === 0 ? "100px" : "" }}
            >
                {!this.props.location.pathname.includes("/no_team") && this.props.isDesktopSize && (
                    <SideBarWrapper Child={InlineSideBar} />
                )}
                <div style={{ minHeight: $("#inline-sidebar").height() + 200 + "px" }}>
                    <Suspense>
                        <Switch>
                            {[
                                !this.props.isTeamLead && NonAdminRoutes,
                                this.props.isTeamLead && Admin_Routes,
                                this.props.isAdmin && Admin_Routes,
                                <Redirect key={"redirect-main"} to="/" />,
                            ]}
                        </Switch>
                    </Suspense>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        user: safe_get(store, "user.user", {}),
        teams: safe_get(store, "teams_helper.teams", []),
        currentTeam: safe_get(store, "teams_helper.team", {}),
        organization: safe_get(store, "org_helper.organization", {}),
        isDesktopSize: safe_get(store, "app.desktopSize", true),
    };
};

export default withRouter(connect(mapStateToProps)(RoutesList));
