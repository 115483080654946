import React from 'react';

import piexif from 'piexifjs';
import $ from 'jquery'
import axios from 'axios';
import moment from 'moment';
import dayjs from "dayjs";
import { htmlToText as htmlToTextLib } from 'html-to-text';

import {v4 as uuidv4} from 'uuid';

// import default_profile_pic from '../assets/css/img/defaultProfilePic.png'
import play_button from '../assets/css/img/play-button.svg'
import pdf_icon from '../assets/css/img/pdf.svg'
import ppt_icon from '../assets/css/img/ppt.svg'
import excel_icon from '../assets/css/img/xls.svg';
import zip_icon from '../assets/css/img/zip.svg';
import doc_icon from '../assets/css/img/doc.svg';

import {
    get_mobile_enabled_cookie, update_organization_uuid,
    update_team_cookie, get_initial_data, get_presigned_url,
    dashboard_info,
    publicPresignFileUpload
} from '../api/zero-api';

import * as appActions from '../store/actions/AppActions';
import * as organizationAction from '../store/actions/OrganizationActions';
import * as teamsActions from '../store/actions/TeamsActions';
import * as notificationsActions from '../store/actions/NotificationsActions';
import * as libraryHelperActions from '../store/actions/LibraryActions';

import {
    update_feed_categories, update_feed_statuses, update_feed_tags,
    update_feed_tab, update_date_query, update_feed_position, update_feed_query
} from '../store/actions/FeedHelperActions';

import {
    updateIncidentsFilterQuery,
    updateIncidentsPeriodQuery,
    updateIncidentsPagination,
    update_incidents_locations,
    update_current_location
} from '../store/actions/IncidentsActions';

import {
    updateLatestActivity,
    updateAssignmentsCount,
    updateOpenAssignments,
    updateClosedAssignments,
    updateOpenPostAssignments,
    updateClosedPostAssignments,
    updateOpenFormAssignments,
    updatePastFormAssignments,
    updateLoadingDashboard
} from '../store/actions/DashboardActions.js';

import {updateFormsFilterQuery, updateFormsPeriodQuery} from '../store/actions/FormsActions'

import {update_user_analytics, update_current_user, reset_user} from '../store/actions/UserActions'
import { getStore } from 'store/store';
import NotificationAlert from './NotificationAlert';

import { GLOBAL_ATTACHMENT_LIMIT, UserRole } from './Constants';
import { escape, get, isNil, isString, isUndefined, keyBy } from 'lodash-es';
import { INITIAL_FORMS_PERIOD_QUERY } from 'store/helpers/defaults';

const USER_ROLES = Object.values(UserRole);
let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function generateUUID() {
    return uuidv4();
}

export function isProduction() {
    // return true;
    return window.location.href.includes("app.teamzero.com") || window.location.href.includes("zero-production.herokuapp.com") || window.location.href.includes("zero-beta.herokuapp.com");
}

export function isStaging() {
    return window.location.href.includes("zero-staging.herokuapp");
}

export function isTesting() {
    return window.location.href.includes("zero-testing.herokuapp.com")
}

export function isPublicPage() {
    return window.location.pathname.startsWith("/public/");
}

export function isMobileApp() {
    var cookie = get_mobile_enabled_cookie();
    // return true;
    return (cookie !== undefined && cookie !== "" && cookie !== "false");
}

export function isAndroid() {
    var userAgent = navigator.userAgent.toLowerCase();
    var isAndroid = userAgent.indexOf("android") > -1;
    return isAndroid;
}

export function iOS() {

    var iDevices = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ];

    if (!!navigator.platform) {
        while (iDevices.length) {
            if (navigator.platform === iDevices.pop()) {
                return true;
            }
        }
    }

    return false;
}

export function isIphoneX() {
    // Taken from: https://stackoverflow.com/questions/47226824/iphonex-and-notch-detection
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var ratio = window.devicePixelRatio || 1;

    var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio
    };

    // iPhone X Detection
    if (iOS && screen.width === 1125 && screen.height === 2436) {
        return true;
    }
    return false;
}

export function isIphoneXr() {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var ratio = window.devicePixelRatio || 1;

    var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio
    };

    // iPhone Xr Detection
    if (iOS && screen.width === 828 && screen.height === 1792) {
        return true;
    }
    return false;
}

export function isIphoneXsMax() {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var ratio = window.devicePixelRatio || 1;

    var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio
    };

    // iPhone Xr Detection
    if (iOS && screen.width === 1242 && screen.height === 2688) {
        return true;
    }
    return false;
}

export function isIpadPro() {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var ratio = window.devicePixelRatio || 1;

    var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio
    };

    // iPad (11in) Detection
    if (iOS && screen.width === 1668 && screen.height === 2388) {
        return true;
    }

    // iPad (12.9in) Detection
    if (iOS && screen.width === 2048 && screen.height === 2732) {
        return true;
    }
    return false;
}

export function isIE() {
    var userAgent, ieReg, ie;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    ie = ieReg.test(userAgent);
    return ie;
}

export function isEdge() {
    var userAgent = window.navigator.userAgent;
    return userAgent.indexOf("Edge") > -1
}

export function isHeadlessChrome() {
    return window.navigator.userAgent.includes("HeadlessChrome");
}

export function floatingButtonMargin() {
    let floatingButtonMargin = "40px";
    if (isMobileApp()) {
        floatingButtonMargin = "calc(80px + var(--sab))";
    }
    return floatingButtonMargin
}


export function needsImageFix() {
    var userAgent = window.navigator.userAgent;
    return (isIE() || userAgent.indexOf("Edge/15") > -1)
}

export function safe_get(object, path, defaultValue) {
    const value = get(object, path, defaultValue);
    
    if (isNil(value) || isUndefined(value)) {
        return defaultValue;
    }

    return value
}

export function getErrorMessage(error, defaultMessage) {
    let errorMessages = safe_get(error, "error_messages", []);
    if (errorMessages.length > 0) {
        let errorMessageObj = errorMessages[0];
        let errorMessage = safe_get(errorMessageObj, "message", defaultMessage)
        return errorMessage;
    } else {
        return defaultMessage
    }
}

/**
 * @param {Response} response 
 * @param {string} [defaultMessage] 
 * @returns {Promise<string>}
 */
export async function getErrorMessageFromResponse(response, defaultMessage) {
    if (!defaultMessage) {
        defaultMessage = "An unknown error has occurred.";
    }

    try {
        if (response instanceof Response === false) {
            return defaultMessage;
        }
        const messages = await extractErrorMessages(response);
        const firstMessage = messages?.[0];
        if (typeof firstMessage === 'string') {
            return firstMessage;
        }
        return firstMessage?.message ?? defaultMessage;
    } catch (err) {
        return defaultMessage;
    }
}

/**
 * @param {Response} response 
 * @returns {(ResponseErrorMessage[] | undefined)}
 */
export async function extractErrorMessages(response) {
    const data = await response.json();
    return data.error_messages;
}

export async function mapErrorMessages(error, mapping) {
    const mappedErrors = {};

    if (error.status === 422) {
        try {
            const data = await error.json();
            const error_messages = safe_get(data, "error_messages", []);

            for (const key in mapping) {
                for (const err of error_messages) {
                    const errorMsg = safe_get(err, "message", "");
                    const errorField = safe_get(err, "field", "");

                    if (errorField === key) {
                        mappedErrors[mapping[key]] = errorMsg;
                    }
                }
            }
        } catch (err) {

        }
    }

    return mappedErrors;
}

/**
 * @param {any} error 
 * @param {string} defaultMessage 
 * @returns {string}
 */
export function getErrorString(error, defaultMessage) {
    if (isString(error)) {
        return error;
    }

    if (error?.message) {
        return error.message;
    }

    return defaultMessage;
}

export const defaultMyTeams = {
    "uuid": "my_teams",
    "name": "My Teams",
    "description": "Viewing all posts across all of your teams.",
    "users_count": 0
}

export function stringToBoolean(string) {
    switch (string.toLowerCase().trim()) {
        case "true":
            return true;
        case "false":
            return false;
        default:
            return Boolean(string);
    }
}

export function postImageContain(imgClass) {
    if (needsImageFix()) {
        $(imgClass).each(function () {
            var t = $(this),
                s = 'url(' + t.attr('src') + ')',
                p = t.parent(),
                d = $('<div></div>');

            p.append(d);
            d.css({
                'background-size': 'contain',
                'background-repeat': 'no-repeat',
                'background-image': s,
                'height': imgClass === "img.post-img" ? '100px' : '48px',
                'width': imgClass === "img.post-img" ? '100px' : '48px',
                'background-color': '#f6f6f6',
                'border': '1px solid #e5e5e5',
                'border-radius': '2px',
                'background-position': 'center center'
            });
            t.remove();
        })

    }
}

export function profileImageContain(imgClass, size) {
    if (needsImageFix()) {
        $(imgClass).each(function () {
            var t = $(this),
                s = 'url(' + t.attr('src') + ')',
                p = t.parent(),
                d = $('<div></div>');

            p.append(d);
            d.css({
                'background-size': 'contain',
                'background-repeat': 'no-repeat',
                'background-image': s,
                'height': size,
                'width': size,
                // 'background-color': '#f6f6f6',
                // 'border': '1px solid #e5e5e5',
                'border-radius': '50%',
                'background-position': 'center center',
                'display': 'inline-block',
                'vertical-align': 'middle',
                'margin-top': '5px',
                'margin-right': '5px'
            });
            t.remove();
        })
    }
}

export function containerClass() {
    // if (isMobileApp()) {
    //     return "container-with-header-footer"
    // }
    // else {
    //     return "container-with-header"
    // }

    return "container-with-header"
}

export function mainContentContainerClass() {
    // if (isMobileApp()) {
    //   return "main-content-container-app"
    // }
    // else {
    //   return "main-content-container"
    // }
    return "main-content-container"
}

export function isAdminPage(location) {
    var whiteListed = [
        "access_teams", "manage_admins", "manage_tags", "manage_forms_templates",
        "manage_incidents_locations", "manage_incident_templates", "manage_schedules",
        "manage_form_alerts", "manage_courses", "manage_kiosks", "create_organization", "settings", "directory",
        "reset_password", "create_team", "edit_team", "users-edit", "users", "myprofile"
    ]

    for (var i in whiteListed) {
        if (location.includes(whiteListed[i])) {
            return true;
        }
    }
    return false
}

export function beforeFileUpload(file, fileList, filenumb) {
    const MAX_ATTACHMENT_COUNT = GLOBAL_ATTACHMENT_LIMIT;
    const MAX_FILE_SIZE = 20;
    const MAX_VIDEO_SIZE = 500;

    const fileMaxSize = file.size / (1024 * 1024) < MAX_FILE_SIZE;
    const videoMaxSize = file.size / (1024 * 1024) < MAX_VIDEO_SIZE;
    var errormsg = '';
    var allowed = false;

    if (filenumb + fileList.length > MAX_ATTACHMENT_COUNT) {
        errormsg = `Maximum of ${MAX_ATTACHMENT_COUNT} attachments allowed.`;
        allowed = false;
        return [allowed, errormsg];
    }

    if (isVideo(file.name)) {
        if (!videoMaxSize) {
            errormsg = `Video must be less than ${MAX_VIDEO_SIZE} MB`;
            allowed = false;
        } else {
            allowed = true;
        }
    } else {
        if (!fileMaxSize) {
            errormsg = `Non-video attachment must be less than ${MAX_FILE_SIZE} MB`;
            allowed = false;
        } else {
            allowed = true;
        }
    }
    return [allowed, errormsg];
}


export function isImage(file_name) {
    if (file_name) {
        return (
            file_name.toLowerCase().indexOf(".jpeg") > -1 ||
            file_name.toLowerCase().indexOf(".jpg") > -1 ||
            file_name.toLowerCase().indexOf(".png") > -1 ||
            file_name.toLowerCase().indexOf(".gif") > -1
        );
    } else {
        return false;
    }

}

export function isVideo(file_name) {
    if (file_name) {
        return (
            file_name.toLowerCase().indexOf(".mpeg") > -1 ||
            file_name.toLowerCase().indexOf(".mp4") > -1 ||
            file_name.toLowerCase().indexOf(".mov") > -1 ||
            file_name.toLowerCase().indexOf(".m4v") > -1 ||
            file_name.toLowerCase().indexOf(".quicktime") > -1
        );
    } else {
        return false;
    }

}

export function getFileThumbnail(file, blobs = {}) {
    let thumbnail = doc_icon;
    let file_name = safe_get(file, "file_name", "").toLowerCase();
    let mime_type = safe_get(file, "mime_type", "");
    let attachmentId = safe_get(file, "attachment_uuid", "");
    const publicUrl = safe_get(file, "public_url", null);
    const publicUrlIsBlob = publicUrl?.startsWith('blob:') ?? false;

    if (isVideo(file_name)) {
        if (navigator.onLine || !publicUrlIsBlob) {
            var video_thumbnail = safe_get(file, "thumbnails.small", null) || safe_get(file, "thumbnails.medium", null) || publicUrl;
            thumbnail = video_thumbnail || play_button;
        } else {
            thumbnail = publicUrl || play_button;
        }
    } else if (isImage(file_name)) {
        if (!isHeadlessChrome() && attachmentId in blobs) {
            return blobs[attachmentId].url;
        }
        
        if (navigator.onLine || !publicUrlIsBlob) {
            thumbnail = safe_get(file, "thumbnails.small", null) || safe_get(file, "thumbnails.medium", null) || publicUrl;
        } else {
            thumbnail = publicUrl;
        }
    } else if (file_name.includes(".zip") || mime_type.includes("application/zip")) {
        thumbnail = zip_icon;
    } else if (file_name.includes(".pdf") || mime_type.includes("pdf")) {
        thumbnail = pdf_icon;
    } else if (file_name.includes(".ppt") || mime_type.includes("powerpoint")) {
        thumbnail = ppt_icon;
    } else if (file_name.includes(".xls") || mime_type.includes("spreadsheet")) {
        thumbnail = excel_icon;
    }
    return thumbnail;
}

function checkRole(user, role) {
    /** @type {string} */
    const userRole = safe_get(user, "role", "");
    /** @type {string[]} */
    const userRoles = safe_get(user, "roles", []);
    return userRoles.includes(role) || userRole === role;
}

export function isRoleOrHigher(user, minimumRole) {
    const userRole = user.role || user.roles?.[0] || UserRole.INVALID;
    const minimumRoleIndex = USER_ROLES.indexOf(minimumRole);
    if (minimumRoleIndex === -1) {
        throw new Error(`Invalid minimum role: ${minimumRole}`);
    }
    let userRoleIndex = USER_ROLES.indexOf(userRole);
    if (userRoleIndex === -1) {
        userRoleIndex = UserROle.INVALID;
    }
    return userRoleIndex <= minimumRoleIndex;
}

export function isAdmin(user) {
    return checkRole(user, "admin");
}

export function isTeamLead(user) {
    return checkRole(user, "team_lead");
}

export function isAdminOrTeamLead(user) {
    return isAdmin(user) || isTeamLead(user);
}

export function isUser(user) {
    return checkRole(user, "user");
}

export function isContributor(user) {
    return checkRole(user, "user_restricted");
}

export function isViewer(user) {
    return checkRole(user, "viewer");
}


export function isPublicUser(user) {
    return checkRole(user, "public");
}


export function isRestrictedTeamUser(team, user) {
    let restrictedRole = safe_get(team, "minimum_role_to_post", "");
    if (restrictedRole === "admin") {
        return !isAdmin(user);
    } else if (restrictedRole === "team_lead") {
        return (!isAdmin(user) && !isTeamLead(user))
    }
    return false;
}

export function canManageSpaces(user) {
    const profile = safe_get(user, 'profile', {});
    return safe_get(profile, 'can_manage_spaces', false);
}

export function safeProfilePic(user = {}, imgClass = "", initialsClass = "", style = {}) {
    var avatar_data = safe_get(user, "avatar_data", {});
    if (navigator.onLine && avatar_data.img_uploaded) {
        const urls = avatar_data?.urls ?? {};
        let url = urls.small || urls.medium || urls.original || null;
        if (!url) {
            style.backgroundColor = avatar_data.bg_color;
            return (
                <div className={"default-avatar-circle " + initialsClass} style={style}>
                    <p className="initials">{avatar_data.initials}</p>
                </div>
            )
        }
        return (<img src={url} className={imgClass} style={style} alt="Profile Pic"/>)
    } else {
        style.backgroundColor = avatar_data.bg_color;
        return (
            <div className={"default-avatar-circle " + initialsClass} style={style}>
                <p className="initials">{avatar_data.initials}</p>
            </div>
        )
    }
}

export function fieldIsNotEmpty(value) {
    if (value) {
        return /\S/.test(value);
    } else {
        return false;
    }
}

export const scheudleFrequency = {
    DAILY: 0,
    WEEKDAY: 1,
    WEEKLY: 2,
    MONTHLY: 3,
    SEND_ONCE: 4,
    QUARTERLY: 5,
    BIANNUALLY: 6,
    ANNUALLY: 7,
    WEEKEND: 8
}

export function getScheduleFrequency(repeat_period) {
    switch (repeat_period) {
        case scheudleFrequency.DAILY:
            return "Daily"
        case scheudleFrequency.WEEKDAY:
            return "Weekdays"
        case scheudleFrequency.WEEKLY:
            return "Weekly"
        case scheudleFrequency.MONTHLY:
            return "Monthly"
        case scheudleFrequency.SEND_ONCE:
            return "Send Once"
        case scheudleFrequency.QUARTERLY:
            return "Quarterly"
        case scheudleFrequency.BIANNUALLY:
            return "Biannually"
        case scheudleFrequency.ANNUALLY:
            return "Annually"
        case scheudleFrequency.WEEKEND:
            return "Weekends"
        default:
            break;
    }
}

export function timeSinceDate(date) {
    var seconds = Math.floor((new Date() - new Date(date * 1000)) / 1000);

    var interval = Math.floor(seconds / 31536000);

    // if (interval > 1) {
    //     return interval + " years";
    // }
    // interval = Math.floor(seconds / 2592000);
    // if (interval > 1) {
    //     return interval + " months";
    // }
    interval = Math.floor(seconds / 86400);
    if (interval > 7) {
        return parseInt(interval / 7) + " weeks";
    } else if (interval > 1) {
        return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

export function dateFormatterWithTime(timestamp, tz = null) {
    var date = new Date(timestamp * 1000);

    var year = date.getFullYear();
    var month = monthsList[date.getMonth()];
    var day = date.getDate();

    const options = {hour: '2-digit', minute: '2-digit', hour12: true};
    if (tz) {
        options.timeZone = tz;
    }
    var time = date.toLocaleTimeString([], options);

    // return month + " " + parseInt(day) + ", " + year + " at " + time;
    return `${month} ${parseInt(day, 10)}, ${year} at ${time}`
}

export function dateFormatterNoTime(timestamp) {
    let date = new Date(timestamp * 1000);

    var year = date.getFullYear();
    var month = monthsList[date.getMonth()];
    var day = date.getDate();

    return `${month} ${parseInt(day, 10)}, ${year}`
}

export function dateFormatterWithTimeNoYear(timestamp) {
    var date = new Date(timestamp * 1000);

    // var year = date.getFullYear();
    var month = monthsList[date.getMonth()];
    var day = date.getDate();

    var time = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true})

    // return month + " " + parseInt(day) + ", " + year + " at " + time;
    return `${month} ${parseInt(day, 10)} at ${time}`
}

export function dateFormatterFromString(date_str) {
    if (date_str) {
        let date = date_str.split("-")
        let year = date[0]
        let month = intToMonth(parseInt(date[1])).substring(0, 3)
        let day = parseInt(date[2], 10)

        return `${month} ${day}, ${year}`
    } else {
        return ""
    }
}

export function dateFormatterWithDayNoYear(dateObj) {
    if (dateObj) {
        const day = intToDay(dateObj.getDay());
        const date = dateObj.getDate();
        const month = intToMonth(dateObj.getMonth() + 1);

        return `${day}, ${month} ${date}`
    }
    return '';
}

/**
 * @returns {number} The current time as a Unix timestamp (in seconds)
 */
export function getCurrentTimestamp() {
    return Math.floor(Date.now() / 1000);
}


export function validatePhoneNumber(value) {
    if (value) {
        let myPhoneRegex = /^\+?1?\d{9,15}$/;
        var phone_value = value.replace(/\s+/g, '')
        phone_value = phone_value.replace(/-/g, '')
        phone_value = phone_value.replace(/\(/g, '')
        phone_value = phone_value.replace(/\)/g, '')

        return myPhoneRegex.test(phone_value)
    }
    return false;
}

export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function monthToInt(month) {
    let months = moment.monthsShort()
    return months.indexOf(month) + 1;
}

export function intToMonth(i) {
    let months = moment.monthsShort()
    return months[i - 1];
}

export function intToDay(i) {
    let days = moment.weekdays()
    return days[i];
}

export function showLoadingTinyMCE() {
    let x = document.getElementsByClassName("tox-dialog__footer-start")[0];

    if (x) {
        let loading = document.createElement("div")
        loading.className = "load3"

        let load = document.createElement("div")
        load.className = "loader"
        load.style = "height:34px;width:34px;margin:0";

        loading.appendChild(load)

        x.appendChild(loading)
    }
}

export function hideLoadingTinyMCE() {
    let x = document.getElementsByClassName("load3")[0];
    if (x) {
        x.remove();
    }
}

// used for testing file uploads
export async function fakeFileUpload(shouldFail, _params, _file, onProgress, onSuccess, onError) {
    try {
        for (let progress = 10; progress < 100; progress += 10) {
            await sleep(randomInt(100, 300));
            onProgress(progress);
        }

        await sleep(200);

        if (shouldFail === true) {
            onError(new Error('Fake file upload failure'));
        } else {
            onSuccess();
        }
    } catch (err) {
        console.error(err);
    }
}

function presignedFileUpload(presignData, file, progress, responseSuccess, responseError) {
    let pre_signed_url = presignData.url
    let fields = presignData.fields
    let formData = new FormData();

    Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value)
    });

    // most calls send the event from the file upload, not the actual file
    // this makes it uniform
    if (file.file) {
        file = file.file;
    }

    const isJpg = file.type === "image/jpeg" || file.type === "image/jpg";

    if (!isJpg && (file.library_embedded || file.form_embedded)) {
        formData.append('file', new Blob([file]));
    } else {
        formData.append('file', file);
    }

    setTimeout(() => {
        axios.post(pre_signed_url, formData, {
            onUploadProgress(progressEvent) {
                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                progress(percentCompleted);
            }
        }).then(response => {
            responseSuccess(pre_signed_url, fields.key);
        }).catch(error => {
            console.log(error)
            responseError(error);
        });
    }, 0);
}

export function fileUpload(context, params, file, progress, responseSuccess, responseError) {
    get_presigned_url(params)
        .then(res => res.json())
        .then(data => {
            presignedFileUpload(data, file, progress, responseSuccess, responseError);
        })
        .catch(responseError);
}

export function publicFileUpload(params, file, progress, responseSuccess, responseError) {
    publicPresignFileUpload(params)
        .then(res => res.json())
        .then(data => {
            presignedFileUpload(data, file, progress, responseSuccess, responseError);
        })
        .catch(responseError);
}

export function getFileData(file, callback) {
    var reader = new FileReader();
    reader.onload = function (e) {
        var exif = piexif.load(e.target.result);
        var image = new Image();
        image.onload = function () {
            var orientation = exif["0th"][piexif.ImageIFD.Orientation];

            if (iOS() || isAndroid()) {
                orientation = image.width === 1 && image.height === 2
            }

            var canvas = document.createElement("canvas");
            var xRatio = 1;
            var yRatio = 1;

            if (image.width > image.height && image.width > 2160) {
                xRatio = 2160.0 / image.width;
                yRatio = 2160.0 / image.width;
            } else if (image.height > image.width && image.height > 2160) {
                xRatio = 2160.0 / image.height;
                yRatio = 2160.0 / image.height;
            }

            var newWidth = image.width * xRatio;
            var newHeight = image.height * yRatio;

            canvas.width = newWidth;
            canvas.height = newHeight;

            var ctx = canvas.getContext("2d");
            var x = 0;
            var y = 0;
            ctx.save();
            if (orientation === 2) {
                x = -canvas.width;
                ctx.scale(-1, 1);
            } else if (orientation === 3) {
                x = -canvas.width;
                y = -canvas.height;
                ctx.scale(-1, -1);
            } else if (orientation === 4) {
                y = -canvas.height;
                ctx.scale(1, -1);
            } else if (orientation === 5) {
                canvas.width = newHeight;
                canvas.height = newWidth;
                ctx.translate(canvas.width, canvas.height / canvas.width);
                ctx.rotate(Math.PI / 2);
                y = -canvas.width;
                ctx.scale(1, -1);
            } else if (orientation === 6) {
                canvas.width = newHeight;
                canvas.height = newWidth;
                ctx.translate(canvas.width, canvas.height / canvas.width);
                ctx.rotate(Math.PI / 2);
            } else if (orientation === 7) {
                canvas.width = newHeight;
                canvas.height = newWidth;
                ctx.translate(canvas.width, canvas.height / canvas.width);
                ctx.rotate(Math.PI / 2);
                x = -canvas.height;
                ctx.scale(-1, 1);
            } else if (orientation === 8) {
                canvas.width = newHeight;
                canvas.height = newWidth;
                ctx.translate(canvas.width, canvas.height / canvas.width);
                ctx.rotate(Math.PI / 2);
                x = -canvas.height;
                y = -canvas.width;
                ctx.scale(-1, -1);
            }

            // ctx.drawImage(image, x, y, canvas.width * xRatio, canvas.height * yRatio);
            ctx.drawImage(image, 0, 0, image.width, image.height,     // source rectangle
                x, y, newWidth, newHeight); // destination rectangle

            ctx.restore();

            var dataURL = canvas.toDataURL("image/jpeg", 1.0);
            var jpegBinary = atob(dataURL.split(",")[1]);

            var fileData = getDataArray(jpegBinary)
            if (callback) {
                callback(fileData)
            }

        };
        image.src = e.target.result;
    };

    reader.readAsDataURL(file);

}

function getDataArray(binStr) {
    var array = [];
    for (var p = 0; p < binStr.length; p++) {
        array.push(binStr.charCodeAt(p));
    }

    var u8array = new Uint8Array(array);

    // var req = new XMLHttpRequest();
    // req.open("POST", "/jpeg", false);
    // req.setRequestHeader('Content-Type', 'image/jpeg');
    // req.send(u8array.buffer);

    return u8array.buffer;
}

export function hideFooterMobileApp() {
    var el = document.getElementById("footer");
    if (isMobileApp() && el) {
        el.style.visibility = "hidden";
    }

}

export function showFooterMobileApp() {
    var el = document.getElementById("footer");
    if (isMobileApp() && el) {
        el.style.visibility = "visible";
    }
}

export function arrayIntersect(a, b) {
    var setB = new Set(b);
    return [...new Set(a)].filter(x => setB.has(x));
}


//
// BULLETIN FEED FILTERS HELPER FUNCTIONS 
//

export function isMyPosts(value) {
    return value.includes("my_posts_only");
}

export function isSubStatus(value) {
    return value.includes("sub_status")
}

export function getSubStatus(value) {
    var sub_status = value.split("sub_status_uuid:")[1]
    return sub_status;
}

export function isStatus(value) {
    return value.includes("status:")
}

export function getStatus(value) {
    var option = value.split("status:")[1]
    var status = ""
    if (option === "0") {
        status = "closed";
    } else if (option === "1") {
        status = "open"
    }
    return status;
}

export function isDateRange(value) {
    return value.includes("days:")
}

export function getDateRange(value) {
    var date_range = value.split("days:")[1]
    return date_range;
}

export function isRiskLevel(value) {
    return value.includes("risk:");
}

export function getRiskLevel(value) {
    var risk_level = value.split("risk:")[1]
    return risk_level;
}

export function isTag(value) {
    // var regex = /^([a-zA-Z0-9]){8}-(([a-zA-Z0-9]){4}-){3}([a-zA-Z0-9]){12}$/;
    // return regex.test(value)
    return value.includes("tag_uuid:");
}

export function getTag(value) {
    var tag = value.split("tag_uuid:")[1]
    return tag;
}

export function isCategory(value) {
    return value.includes("category_uuid:");
}

export function getCategory(value) {
    var category = value.split("category_uuid:")[1]
    return category;
}


export function is_user_uuid(value) {
    var regex = /^([a-zA-Z0-9]){8}-(([a-zA-Z0-9]){4}-){3}([a-zA-Z0-9]){12}$/;
    return regex.test(value)
}


// SOURCE: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    var results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';

    if (name === "email") {
        return decodeURIComponent(results[2]);
    } else {
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

}

export function getQueryParam(name, defaultValue = undefined) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name) ?? defaultValue;
}

export function isObjEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function deepObjectCompare(obj1, obj2) {
    //Loop through properties in object 1
    for (var p in obj1) {
        //Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        switch (typeof (obj1[p])) {
            //Deep compare objects
            case 'object':
                if (!deepObjectCompare(obj1[p], obj2[p])) return false;
                break;
            //Compare function code
            case 'function':
                if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
                break;
            //Compare values
            default:
                if (obj1[p] !== obj2[p]) return false;
        }
    }
    //Check object 2 for any extra properties
    for (var i in obj2) {
        if (typeof (obj1[i]) === 'undefined') return false;
    }
    return true;
}


export function switchOrganization(context, new_org_uuid) {

    var self = context;

    self.props.dispatch(appActions.update_main_loading(true));
    self.props.dispatch(updateLoadingDashboard(true));

    self.props.dispatch(update_feed_position(0));
    self.props.dispatch(update_feed_query("?team_uuid=my_teams"));
    self.props.dispatch(update_date_query("&period=all_time"));
    self.props.dispatch(update_feed_tab("posts"));

    self.props.dispatch(updateFormsFilterQuery("?team_uuid=my_teams"));
    self.props.dispatch(updateFormsPeriodQuery(INITIAL_FORMS_PERIOD_QUERY));

    self.props.dispatch(updateIncidentsFilterQuery(""));
    self.props.dispatch(updateIncidentsPeriodQuery("&period=all_time"));
    self.props.dispatch(updateIncidentsPagination(1));

    var location = {"location_uuid": "all_locations"};
    self.props.dispatch(update_current_location(location));
    self.props.dispatch(updateIncidentsFilterQuery(""));
    self.props.dispatch(updateIncidentsPeriodQuery("&period=all_time"));
    self.props.dispatch(updateIncidentsPagination(1));

    update_organization_uuid(new_org_uuid);
    self.props.dispatch(teamsActions.update_current_team({}));

    get_initial_data().then(function (success) {
        success.json().then(success => {

            if (success.kiosk_mode_on) {
                self.props.history.replace('/kiosk_mode');
                return;
            }

            var user = safe_get(success, "user", {});
            self.props.dispatch(reset_user());
            self.props.dispatch(update_current_user(user));

            let organizations = safe_get(success, "organizations", []);
            self.props.dispatch(organizationAction.update_organizations(organizations));

            var organization = safe_get(success, "organization", {});
            self.props.dispatch(organizationAction.update_current_org(organization));

            // var locations = safe_get(success, "incident_reporting.locations", []);
            // locations.sort(function(a,b) {
            //     return a.name > b.name ? 1 : -1;
            // });
            self.props.dispatch(update_incidents_locations([]));
            // self.props.dispatch(incidentsActions.update_current_location(locations[0]));

            // let bulletin_tags = safe_get(success, "bulletin_board.tags", []);
            self.props.dispatch(update_feed_tags([]));

            // let bulletin_categories = safe_get(success, "bulletin_board.categories", []);
            self.props.dispatch(update_feed_categories([]));

            // let bulletin_statuses = safe_get(success, "bulletin_board.sub_statuses", []);
            self.props.dispatch(update_feed_statuses([], 1));
            self.props.dispatch(update_feed_statuses([], 2));

            self.props.dispatch(libraryHelperActions.update_topics([]));

            let all_notifications = safe_get(success, "notifications_data.notifications", []);
            formatNotifications(self, all_notifications, user);

            let unread_notifications_counter = safe_get(success, "notifications_data.total_unread", 0);
            self.props.dispatch(notificationsActions.update_unread_notifications_counter(unread_notifications_counter));

            // let post_assignments = safe_get(success, "post_assignments", []);
            // formatAssignments(self, post_assignments);

            // let form_assignments = safe_get(success, "form_assignments", []);
            // formatFormsAssignments(self, form_assignments);


            self.props.dispatch(updateAssignmentsCount(safe_get(success, "assignment_count", 0)));

            var is_kiosk_mode = safe_get(success, "kiosk_mode_user", false)
            self.props.dispatch(appActions.update_kiosk_mode(is_kiosk_mode));

            var teams = safe_get(success, "teams", []);
            teams.sort(function (a, b) {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            });

            self.props.dispatch(teamsActions.reset_teams());
            self.props.dispatch(teamsActions.update_teams(teams));

            var organization_uuid = safe_get(success, "organization.organization_uuid", "undefined")

            if (organization.account_locked) {
                window.location = "/account_locked";
            } else if (teams.length === 0) {
                self.props.history.replace("/" + organization_uuid + "/home/myprofile/" + user.uuid);
                self.props.dispatch(teamsActions.update_current_team({}));
            } else {
                update_team_cookie("my_teams");
                self.props.dispatch(teamsActions.update_current_team(defaultMyTeams));
                // self.props.history.push("/" + organization_uuid + "/home/team/" + first_team.uuid);
            }

            self.props.history.replace("/" + organization_uuid + "/home/dashboard");
            self.props.dispatch(appActions.update_main_loading(false));

        });
    }, function (error) {
        console.log(error);
    });

}

export function formatNotifications(context, all_notifications, user) {
    var self = context;
    var unread_notifications = [];
    var read_notifications = [];

    for (var i in all_notifications) {
        var notification = all_notifications[i];
        if (notification.user.uuid !== user.uuid) {

            if (notification.read_at !== undefined) {
                read_notifications.push(notification);
            } else {
                unread_notifications.push(notification);
            }

        }
    }

    unread_notifications.sort(function (a, b) {
        return b.timestamp - a.timestamp;
    });

    read_notifications.sort(function (a, b) {
        return b.timestamp - a.timestamp;
    });

    // self.props.dispatch(notificationsActions.reset_notifications());
    self.props.dispatch(notificationsActions.update_notifications(unread_notifications));
    self.props.dispatch(notificationsActions.update_read_notifications(read_notifications));
}

export function updateAssignments(dispatch) {
    dashboard_info()
        .then(res => res.json())
        .then(data => {
            let stats = safe_get(data, "user_stats.user_stats", {});
            dispatch(update_user_analytics(stats));
            dispatch(updateAssignmentsCount(stats.open_assignments));

            let openAssignments = safe_get(data, "open_assignments.assignments", []);
            dispatch(updateOpenAssignments(openAssignments));

            let closedAssignments = safe_get(data, "closed_assignments.assignments", []);
            dispatch(updateClosedAssignments(closedAssignments));

            let latestActivity = safe_get(data, "recent_posts", []).splice(0, 5);
            dispatch(updateLatestActivity(latestActivity));
        })
        .catch(error => {
            console.error(error);
        });
}

export function formatAssignments(context, all_assignments) {
    var self = context;

    var open_assignments = [];
    var closed_assignments = [];

    for (var i in all_assignments) {
        var assignment = all_assignments[i];

        if (assignment.post_status === "open") {
            open_assignments.push(assignment);
        } else if (assignment.post_status === "closed") {
            closed_assignments.push(assignment);
        }
    }

    open_assignments.sort(function (a, b) {
        return b.assigned_at - a.assigned_at;
    });

    closed_assignments.sort(function (a, b) {
        return b.assigned_at - a.assigned_at;
    });

    self.props.dispatch(updateOpenPostAssignments(open_assignments));
    self.props.dispatch(updateClosedPostAssignments(closed_assignments));
}

export function updateFormAssignments(context) {
    var self = context;

    dashboard_info().then(function (success) {
        success.json().then(success => {
            let stats = safe_get(success, "user_stats.user_stats", {});
            self.props.dispatch(update_user_analytics(stats));
            self.props.dispatch(updateAssignmentsCount(stats.open_assignments));

            // let form_assignments = safe_get(success, "form_assignments", []);
            // formatFormsAssignments(self, form_assignments);

            // let post_assignments = safe_get(success, "post_assignments", []);
            // formatAssignments(self, post_assignments);

            let openAssignments = safe_get(success, "open_assignments.assignments", []);
            self.props.dispatch(updateOpenAssignments(openAssignments));

            let closedAssignments = safe_get(success, "closed_assignments.assignments", []);
            self.props.dispatch(updateClosedAssignments(closedAssignments));

            let latestActivity = safe_get(success, "recent_posts", []).splice(0, 5);
            self.props.dispatch(updateLatestActivity(latestActivity));

        });
    }, function (error) {
        console.log(error);
    });
}

export function formatFormsAssignments(context, all_assignments) {
    var self = context;

    var open_assignments = [];
    var past_assignments = [];

    for (var i in all_assignments) {
        var assignment = all_assignments[i];

        let today = moment().format("YYYY-MM-DD");
        let due_date = moment(assignment.due_date);

        if (assignment.completed || due_date.isBefore(today)) {
            past_assignments.push(assignment)
        } else {
            open_assignments.push(assignment)
        }
    }

    open_assignments.sort(function (a, b) {
        return b.assigned_at - a.assigned_at;
    });

    past_assignments.sort(function (a, b) {
        return b.assigned_at - a.assigned_at;
    });

    self.props.dispatch(updateOpenFormAssignments(open_assignments));
    self.props.dispatch(updatePastFormAssignments(past_assignments));
}

export function hasTeamAccess(teams, team_uuid) {
    let found = teams.find(function (team) {
        return team.uuid === team_uuid
    });

    if (found) {
        return true
    } else {
        return false
    }
}

export function scrollToTop(id) {
    if ($("#" + id)[0]) {
        $("#" + id)[0].scrollIntoView(false);
    }
}

export function jumpToTopOfPage() {
    window.scrollTo(0, 0);
}

export function blurActiveElement() {
    if (document.activeElement && document.activeElement.blur) {
        document.activeElement.blur();
    }
}

export function minPageHeight() {
    var sidebar = document.getElementById("inline-sidebar");
    if (sidebar && window.innerWidth > 1100) {
        return sidebar.scrollHeight + 100;
    }
}

export function shouldFormFieldDisplay(field, answers, is_comment = false, is_builder = false, section = undefined, is_submission = false) {
    let logic = safe_get(field, "custom_logic", undefined)

    if (section) {
        if (section.children.includes(field.id) && is_builder) {
            return true
        } else if (section.children.includes(field.id) && is_submission && logic) {
            return fieldLogicRender(logic, answers)
        } else if (section.children.includes(field.id)) {
            return true
        }
        return false
    } else if (is_builder && !is_comment) {
        if (field.sectionId && !section) {
            return false
        }
        return true
    } else if (is_submission) {
        if (field.sectionId && !section) {
            return false
        } else if (logic) {
            return fieldLogicRender(logic, answers)
        }
        return true
    } else if (logic) {
        return fieldLogicRender(logic, answers)
    } else {
        return true
    }

}

function fieldLogicRender(logic, answers) {
    var answer = answers.find(function (obj) {
        return obj.id === logic.trigger_field_id
    })

    if (answer && answer['value']) {
        var is_selected = false;
        if (logic.trigger_option_id) {
            is_selected = answer["value"].indexOf(logic.trigger_option_id) >= 0
        } else if (logic.trigger_value) {
            is_selected = (answer["value"] === logic.trigger_value)
        }

        switch (logic.trigger_result) {
            case "show":
                if (logic.trigger_logic === "is") {
                    return is_selected
                } else if (logic.trigger_logic === "is_not") {
                    return !is_selected
                }
                break;
            case "hide":
                if (logic.trigger_logic === "is") {
                    return is_selected ? false : true
                } else if (logic.trigger_logic === "is_not") {
                    return is_selected ? true : false
                }
                break;
            default:
                break;
        }
    } else {
        return false;
    }
}

export function compareLists(originalList, newList) {
    const newItems = [];
    const deletedItems = [];
    const sameItems = [];

    for (const newItem of newList) {
        if (originalList.includes(newItem)) {
            sameItems.push(newItem);
        } else {
            newItems.push(newItem);
        }
    }

    for (const oldItem of originalList) {
        if (!newList.includes(oldItem)) {
            deletedItems.push(oldItem);
        }
    }

    return [newItems, deletedItems, sameItems];
}

export function sortByString(list, propertyName) {
    return list.sort((a, b) => a[propertyName]?.toLowerCase() > b[propertyName]?.toLowerCase() ? 1 : -1);
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function withRetry(attempts, pause, callback) {
    let lastError = null;
    
    for (let attempt = 0; attempt < attempts; attempt++) {
        try {
            return await callback();
        } catch (err) {
            lastError = err;
            if (pause) {
                await sleep(pause * attempt);
            }
        }
    }

    throw lastError;
}

export function randomInt(start, end) {
    if (end === undefined) {
        end = start;
        start = 0;
    }

    return Math.floor(Math.random() * (end - start + 1)) + start;
}

/**
 * Uses a basic comparison to see if arrays match. Should not be used on arrays of objects unless the
 * objects can be matched using `Object.is`.
 * @param {any[]} array1 
 * @param {any[]} array2 
 * @returns {bool}
 */
export function simpleArraysMatch(array1, array2) {
    if (array1.length !== array2.length) {
        return false;
    }

    for (const elem of array1) {
        if (array2.includes(elem) === false) {
            return false;
        }
    }

    for (const elem of array2) {
        if (array1.includes(elem) === false) {
            return false;
        }
    }

    return true;
}

/**
 * Returns an object that is derived from an array of objects indexed by a property of that object.
 * @param {object[]} array 
 * @param {string} indexProperty 
 * @returns {{[key: string]: object}}
 */
export function indexArrayUsingProperty(array, indexProperty) {
    const mapping = {};
    for (const element of array) {
        const key = element?.[indexProperty]
        if (key === undefined) {
            throw new Error(`Array element does not have property ${indexProperty}`);
        }
        mapping[key] = element;
    }
    return mapping;
}

export const LocalStorageHelper = {
    /**
     * @typedef {'last-offline-sync' | 'post-sync-enabled' | 'debug-logger-filters' | 'zero-data-grid'} LocalStorageKey
     * 
     * @param {LocalStorageKey} key 
     * @param {"string" | "number" | "boolean" | "object"} [as] 
     * @param {*} [defaultValue]
     * @returns {null | undefined | string | number | boolean | object}
     */
    get(key, as="string", defaultValue=null) {
        const raw = localStorage.getItem(key);
        if (raw === null) {
            return defaultValue;
        }

        switch (as) {
            case "number":
                return parseFloat(raw);
            case "boolean":
                return raw === "true";
            case "object":
                return JSON.parse(raw);
        }

        if (raw === "undefined") {
            return undefined;
        } else if (raw === "null") {
            return null;
        }

        return raw;
    },
    /**
     * @param {LocalStorageKey} key 
     * @param {*} value 
     */
    set(key, value) {
        const vType = typeof(value);
        let convertedValue;

        switch (typeof(value)) {
            case "number":
            case "boolean":
                convertedValue = value.toString();
                break;
            case "string":
                convertedValue = value;
                break;
            case "object":
                convertedValue = JSON.stringify(value);
                break;
            case "undefined":
                convertedValue = "undefined";
                break;
        }

        if (convertedValue === undefined) {
            throw new Error(`Cannot serialize value with type ${vType}`);
        }

        localStorage.setItem(key, convertedValue);
    }
}

export function hiddenNavToOfflineDebugIsAllowed() {
    if (location.hostname === 'localhost') {
        return true;
    }

    for (const env of ['staging', 'testing']) {
        if (location.hostname.includes(env)) {
            return true;
        }
    }

    return false;
}

export function getFeatureFlag(flag, defaultValue) {
    const state = getReduxState();
    return state?.org_helper?.organization?.feature_flags?.[flag] ?? defaultValue;
}

export function getReduxStore() {
    const { store } = getStore();
    return store;
}

export function getReduxState() {
    const { store } = getStore();
    return store.getState();
}

/** @returns {boolean} */
export function isOfflineAllowed() {
    return getFeatureFlag('offline', false);
}

/**
 * Capitalizes the first letter of `s`
 * @param {string} s 
 * 
 * @returns {string} `s` with the first letter capitalized
 */
export function titleCase(s) {
    if (s.length === 0) {
        return s;
    }

    return `${s.substring(0, 1).toUpperCase()}${s.substring(1)}`;
}

export function fileHasZeroSize(file) {
    if (file.size === 0) {
        NotificationAlert("error", "", "Files cannot be dragged from a ZIP folder. Please move the files to a normal folder and try again.");
        return true;
    }
    return false;
}

/** Set's the time to 11:59pm UTC, adding or subtracting days as needed*/
export function endOfDayUtc(value) {
    const dayjsValue = dayjs(value);
    const endOfDayUtc = dayjsValue.utc().endOf('day');

    if (dayjsValue.startOf('day').isBefore(endOfDayUtc.local().startOf('day'))) {
        // need to subtract a day from end of day utc
        return endOfDayUtc.subtract(1, 'day');
    } else if (dayjsValue.startOf('day').isAfter(endOfDayUtc.local().startOf('day'))) {
        // need to add a day to end of day utc
        return endOfDayUtc.add(1, 'day');
    }

    return endOfDayUtc;
}

export function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function adjustWorksheetColumnWidths(utils, ws) {
    const range = utils.decode_range(ws['!ref']);
    const startRow = range.s.r;
    const endRow = range.e.r;
    const startCol = range.s.c;
    const endCol = range.e.c;
    for (let c = startCol; c <= endCol; c++) {
        let max = 0;
        for (let r = startRow; r <= endRow; r++) {
            const addr = utils.encode_cell({r, c});
            if (ws[addr]) {
                const v = ws[addr].v;
                if (v && v.length > max) {
                    max = v.length;
                }
            }
        }
        ws['!cols'][c] = {wch: max + 2};
    }
}

function worksheetShowHiddenRows(ws) {
    const hiddenRows = ws['!rows'].filter(row => row.hidden);
    for (const row of hiddenRows) {
        row.hidden = false;
    }
}

export function exportTableToXlsx(tableEl, workbookName) {
    // This is the only place we use xlsx, so we import it async to keep it out of the main bundle
    import("xlsx")
        .then(({utils, writeFileXLSX}) => {
            /** @type {HTMLTableElement} */
            const exportTable = tableEl.cloneNode(true);
            exportTable.querySelectorAll('.sheetjs-no-export').forEach(el => el.parentElement.removeChild(el))
            const wb = utils.table_to_book(exportTable);
            const ws = wb.Sheets.Sheet1;
            adjustWorksheetColumnWidths(utils, ws);
            worksheetShowHiddenRows(ws);
            writeFileXLSX(wb, workbookName);
        })
        .catch((err) => {
            console.error("Could not export to XLSX:", err);
        });
}

export function objectIsEmpty(obj) {
    return Object.keys(obj).length === 0;
}

/**
 * Checks for attachments with a specific name pattern and throws an error if it's found.
 * Does not do anything in prod. Used to test attachment error handling.
 * @param {string} fileName 
 */
export function checkForDebugErrorUpload(fileName) {
    if (isProduction()) {
        return;
    }

    if (fileName.startsWith('zero_error')) {
        throw new Error('Attachment error handling test');
    }
}


/**
 * Creates a URL using the current location's origin + pathname.
 * If a full URL is provided, the origin will be stripped and replaced with the current location's origin.
 * 
 * **Example:** generateUrlForLocation('abc') => 'http://localhost:3000/abc'
 * 
 * **Example:** generateUrlForLocation('http://localhost:3001/abc') => 'http://localhost:3000/abc'
 * @param {string} pathname 
 * @returns {string}
 */
export function generateUrlForLocation(pathname) {
    const {origin} = window.location;

    try {
        const pathnameUrl = new URL(pathname);
        pathname = pathnameUrl.pathname;
    } catch (_err) {
        // pathname is not a full URL, ignore
    }

    if (!pathname.startsWith('/')) {
        pathname = '/' + pathname
    }

    return origin + pathname;
}

export async function loadGoogleMaps() {
    if (!window.google?.maps) {
        throw new Error("Google Maps script loader has not been initialized.");
    }

    const load = (lib) => window.google.maps.importLibrary(lib);

    await Promise.all([
        load('maps'),
        load('places'),
        load('geocoding'),
        load('marker'),
    ])

    return window.google.maps;
}

/**
 * 
 * @param {unknown} prevProps 
 * @param {unknown} props 
 * @param {string[] | string} keys 
 */
export function didPropsChange(prevProps, props, keys) {
    let singularKey = false;
    if (typeof keys === 'string') {
        singularKey = true;
        keys = [keys];
    }
    const keysChanged = keys.filter(key => prevProps[key] !== props[key]);
    if (keysChanged.includes(true)) {
        if (singularKey) {
            return keysChanged[0];
        }
        return keysChanged;
    }
    return false;
}

/**
 * @param {{
 *  $base: string;
 *  [className: string]: any;
 * }} classes 
 * @returns {string}
 */
export function cssClasses({$base = "", ...dynamicClassNames}) {
    let classNames = `${$base}`;
    for (const [key, value] of Object.entries(dynamicClassNames)) {
        if (value) {
            classNames += ` ${key}`;
        }
    }
    return classNames;
}

/**
 * 
 * @param {{first_name: string, last_name: string} | null | undefined} user 
 * @param {{
 *  defaultName: string,
 * }} [options]
 * @returns 
 */
export function formatName(user, options = {}) {
    const {defaultName = "Unknown User"} = options;

    if (!user) {
        return defaultName;
    }

    let fullName = `${user.first_name} ${user.last_name}`.trim();

    if (fullName) {
        return fullName;
    }

    return defaultName;
}

export function joinTeamsAndMembers(data) {
    const members = keyBy(data.members ?? [], 'uuid');
    const teams = [];
    for (const rawTeam of (data.teams ?? [])) {
        const team = {...rawTeam};
        team.created_by = members[rawTeam.created_by];
        team.members = rawTeam.members.map(id => members[id]);
        teams.push(team);
    }
    return teams;
}

/**
 * @param {string} html 
 * @returns {string}
 */
export function htmlToText(html) {
    const text = htmlToTextLib(html, {
        wordwrap: false,
        selectors: [
            { selector: 'p', options: { leadingLineBreaks: 1, trailingLineBreaks: 1 } },
            { selector: 'pre', options: { leadingLineBreaks: 1, trailingLineBreaks: 1 } },
        ]
    });

    return text.replace(/\n\n\n+/g, '\n\n').trim();
}


/**
 * @param {string} text 
 * @returns {string}
 */
export function textToParagraphs(text) {
    if (!text) {
        return text;
    }
    return text
        .split('\n')
        .map(l => {
            l = escape(l.trim());
            if (l.length === 0) {
                return '<br />';
            }
            return `<p>${l}</p>`;
        })
        .join('');
}

/**
 * @param {number} count 
 * @param {string} singular 
 * @param {string} [plural] 
 * @returns {string}
 */
export function pluralize(count, singular, plural) {
    if (!plural) {
        plural = singular + 's';
    }
    return count === 1 ? singular : plural;
}